export const LANDING = "/builder";
export const LOGIN = "/login";
export const HOME = "/";
export const ABOUT = "/about";
export const FAQS = "/faqs";
export const TERMS = "/terms";
export const PRIVACY = "/privacy";
export const FEATURES = "/features";
export const MINISTRY = "/ministry";
export const COACHING = "/coaching";
export const COACHINGDEV = "/coachingdev";
export const PATHWAYS = "/pathways";
export const PATHWAYPREVIEW = "/pathways/:id";
export const PATHWAY_V3PREVIEW = "/pathways_v3/:id";
export const PATHWAY_OGP = "/pathways/og/:id";
export const PROFILE = "/profile";
export const INVITE = "/invite/:inviteCode";
export const CUSTOM_LOGIN = "/customlogin";
