import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as ROUTES from "../../constants/routes.js";
import ErrorBoundary from "../Errors";
import Alerts from "../Alerts";
import CssBaseline from "@mui/material/CssBaseline";

const BuilderPage = lazy(() => import("../Dashboard"));
const LoginPage = lazy(() => import("../SignIn"));
const HomePage = lazy(() => import("../Home"));
const LoadingPage = lazy(() => import("../Loading/loadingScreen"));
const AboutPage = lazy(() => import("../About"));
const FeaturesPage = lazy(() => import("../Features"));
const PathwaysPage = lazy(() => import("../Pathways"));
const PathwayPreviewPage = lazy(() => import("../PathwayPreview"));
const PathwayV3PreviewPage = lazy(() => import("../PathwayV3Preview"));
const CoachingPage = lazy(() => import("../Coaching"));
const TermsPage = lazy(() => import("../Terms"));
const PrivacyPage = lazy(() => import("../Privacy"));
const ProfilePage = lazy(() => import("../Profile"));
const InvitePage = lazy(() => import("../InvitePage"));
const CustomLogin = lazy(() => import("../CustomLogin"));
const PathwayOGP = lazy(() => import("../PathwayOGP"));

const App = () => (
  <div className="app">
    <Router>
      <CssBaseline />
      <div className="app-page-content">
        <Suspense fallback={<LoadingPage />}>
          <ErrorBoundary>
            <Routes>
              <Route exact path={ROUTES.HOME} element={<HomePage />} />
              <Route exact path={ROUTES.LANDING} element={<BuilderPage />} />
              <Route exact path={ROUTES.LOGIN} element={<LoginPage />} />
              <Route exact path={ROUTES.ABOUT} element={<AboutPage />} />
              <Route exact path={ROUTES.FEATURES} element={<FeaturesPage />} />
              <Route exact path={ROUTES.PATHWAYS} element={<PathwaysPage />} />
              <Route exact path={ROUTES.COACHING} element={<CoachingPage />} />
              <Route exact path={ROUTES.TERMS} element={<TermsPage />} />
              <Route exact path={ROUTES.PRIVACY} element={<PrivacyPage />} />
              <Route exact path={ROUTES.PATHWAYPREVIEW} element={<PathwayPreviewPage />} />
              <Route exact path={ROUTES.PATHWAY_V3PREVIEW} element={<PathwayV3PreviewPage />} />
              <Route exact path={ROUTES.PROFILE} element={<ProfilePage />} />
              <Route exact path={ROUTES.INVITE} element={<InvitePage />} />
              <Route exact path={ROUTES.CUSTOM_LOGIN} element={<CustomLogin />} />
              <Route exact path={ROUTES.PATHWAY_OGP} element={<PathwayOGP />} />
            </Routes>
          </ErrorBoundary>
        </Suspense>
      </div>

      {/* <Footer /> */}
      <Alerts />
    </Router>
  </div>
);

export default App;
